import React from "react";

import "./style/stylesheet.scss";
import {ReactComponent as Logo} from './logo.svg';

const App = () => {
  return (
    <div className="page">
      <a href="https://opensea.io/Osmodeus">
        <Logo className="image"/>
      </a>
    </div>
  );
}

export default App;
